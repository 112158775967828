<template>
    <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Estatus Calendario</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-progress-bar
              class="md-warning"
              md-mode="buffer"
            ></md-progress-bar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="estatusId">{{
                item.estatusCalendarioId
              }}</md-table-cell>
              <md-table-cell md-label="Titulo" md-sort-by="descripcion">{{
                item.descripcion
              }}</md-table-cell>
              <md-table-cell md-label="Acciones" style="width: 100px">
                <md-button
                 
                  style="width: 50px"
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  
                  style="width: 50px"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">Agregar Estatus para el Calendario</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template
          slot="body"
        >
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label>Id</label>
                <md-input v-model="estatusId" disabled></md-input>
                <span class="md-helper-text">Id</span> 
              </md-field>
              <md-field>
                <label>{{ datosModal.titulo }}</label>
                <md-input v-model="descripcion" type="text"></md-input>
                <span class="md-helper-text">Descripcion</span>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="createOrUpdateOrDelete(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
      </div>
     </div> 
</template>
<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import servEstatus from "../../Servicios/Catalogos/estatusCalendario.js";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
export default {
    components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
     usuario() {
      return store.state.usuario;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data (){
    return {
        datosModal: {
        titulo: "Crear un evento",
        boton: "Confirmar",
        evento: 0,
      },
       descripcion: "", 
       currentSort: "correoId",
       estatusId: 0,
       currentSortOrder: "asc",
       pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["correoId", "correo"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
    }
  },
  methods: {
    createOrUpdateOrDelete(exp) {
        var dataEstatus = {
          EstatusCalendarioId : this.estatusId,
          Descripcion : this.descripcion,
         };
         switch(exp) {
          case 1: 
          servEstatus 
          .postEstatus(dataEstatus)
          .then((r) => {
            this.ProcesamosResponse(r.data);
          });
          break
          case 2: 
          if(this.estatusId > 0)
          {
            servEstatus 
            .putEstatus(this.estatusId ,dataEstatus)
            .then((r) => {
              this.ProcesamosResponse(r.data);
            });
          }
         break
         }   
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";          
            this.notifyVue(m);
            break;
          case 3: //success
            this.classicModalHide();
            this.getDatos();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getDatos() {
      this.tableData = [];
      servEstatus
        .get()
        .then((p) => {
          this.tableData = p.data;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["correoId", "correo"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-crud-all", e);
        });
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Descripcion";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.Actualizar(row);
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar Estatus";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;
        case 3: //Eliminar
          this.estatusId = row.estatusCalendarioId;
          this.deleteRow();
          break;
       }
     },   
     Actualizar(row){
       this.estatusId = row.estatusCalendarioId;
       this.descripcion = row.descripcion
     },
     deleteRow() {
      if (this.estatusId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.correoId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servEstatus
                .delete(this.estatusId)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.estatusId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
              }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    Clear() {
      this.estatusId = 0;
      this.descripcion = "";
    },
  },
  created() {
    this.getDatos();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
}
</script>