<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Menú</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >{{ item }}</md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)">Nuevo</md-button>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="id">
                {{
                item.id
                }}
              </md-table-cell>
              <md-table-cell md-label="Nombre" md-sort-by="nombre">{{ item.nombre }}</md-table-cell>
              <md-table-cell md-label="Controlador" md-sort-by="controlador">
                {{
                item.controlador
                }}
              </md-table-cell>
              <md-table-cell md-label="Pantalla" md-sort-by="pantalla">{{ item.pantalla }}</md-table-cell>
              <md-table-cell md-label="Menú Padre" md-sort-by="menuPpadreId">{{ item.menuPpadreId }}</md-table-cell>
              <md-table-cell md-label="Área" md-sort-by="areaId">{{ item.areaId }}</md-table-cell>
              <md-table-cell md-label="Orden" md-sort-by="order">{{ item.order }}</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th v-for="item in footerTable" :key="item" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="id" disabled></md-input>
            <span class="md-helper-text">Id</span>
          </md-field>
          <md-field>
            <label>Nombre</label>
            <md-input v-model="nombre"></md-input>
            <span class="md-helper-text">Nombre</span>
          </md-field>
          <md-field>
            <label>Controlador</label>
            <md-input v-model="controlador"></md-input>
            <span class="md-helper-text">Controlador</span>
          </md-field>
          <md-field>
            <label>Pantalla</label>
            <md-input v-model="pantalla"></md-input>
            <span class="md-helper-text">Pantalla</span>
          </md-field>
          <md-field>
            <label>Menú Padre</label>
            <md-input v-model="menuPpadreId"></md-input>
            <span class="md-helper-text">Menú Padre</span>
          </md-field>
          <md-field>
            <label>Área</label>
            <md-select v-model="areaId">
              <md-option 
                v-for="a in areacol"
                :key="a.areaId"
                :value="a.areaId"
              >
                {{ a.areaDescripcion}}
            
              </md-option>
            </md-select>
            <span class="md-helper-text">Área Id</span>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
          >{{ datosModal.boton }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>
    
    <script>
import { Pagination, Modal } from "@/components"; 
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import sermenu from "../../Servicios/Seguridad/menu"; 
import servearea from "../../Servicios/Area/area.js";
export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "id",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "Id",
        "Nombre",
        "Controlador",
        "Pantalla",
        "Menú Padre",
        "Área",
        "Order",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: [
        "id",
        "nombre",
        "controlador",
        "pantalla",
        "menuPpadreId",
        "areaId",
        "order",
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      id: 0,
      nombre: null,
      controlador: null,
      pantalla: null,
      menuPpadreId: null,
      areaId: null,
      order: null,
      datosModal: {
        titulo: "Crear un nuevo Menú",
        boton: "Confirmar",
        evento: 0,
      },
      areacol:[]
    };
  },
  methods: {
    getArea(){
      servearea
        .all()
        .then(r=>{
          this.areacol=r.data}
      ).catch(e=>console.log("Error en caragr combo Area"  +e))
    
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
  var data = {
    id: this.id,
    nombre: this.nombre,
    controlador: this.controlador,
    pantalla: this.pantalla,
    menuPpadreId: this.menuPpadreId,
    areaId: this.areaId,
    order: this.order,
  };
  
  switch (exp) {
    case 1: // Crear
      sermenu
        .post(data)
        .then((r) => {
          this.classicModal = false;
          this.getDatos(); // Actualizar la tabla inmediatamente
          let mens = mensaje.generico(r.config.method, r.status);
          if (mens.accion == true) {
            this.notifyVue(mens);
          }
        })
        .catch((e) => {
          mensaje.Error("Menú-crud-post", e);
        });
      break;

    case 2: // Actualizar
      if (this.id > 0) {
        sermenu
          .put(this.id, data)
          .then((r) => {
            this.classicModal = false;
            this.getDatos(); // Actualizar la tabla inmediatamente
            let mens = mensaje.generico(r.config.method, r.status);
            if (mens.accion == true) {
              this.notifyVue(mens);
            }
          })
          .catch((e) => {
            mensaje.Error("menu-crud-put", e);
          });
      }
      break;
  }
},

    getDatos() {
  sermenu.all()
    .then((a) => {
      this.tableData = a.data;
      console.log("Datos actualizados:", this.tableData); // Verifica que los datos se cargan correctamente
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3,
      });
    })
    .catch((e) => {
      mensaje.Error("menú-all", e);
    });
},
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear un Menú";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.id = row.id;
          this.nombre = row.nombre;
          this.controlador = row.controlador;
          this.pantalla = row.pantalla;
          this.menuPpadreId = row.menuPpadreId;
          this.areaId = row.areaId;
          this.order = row.order;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar Menú";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.id = row.id;
          this.deleteRow(this.id);
          break;
      }
    },
    deleteRow(id) {
  if (this.id > 0) {
    Swal.fire({
      title: "!Eliminar!",
      text: `¿Deseas eliminar el siguiente registro: ${this.id} ?`,
      type: "success",
      showCancelButton: true,
      confirmButtonClass: "md-button md-success",
      cancelButtonClass: "md-button md-danger",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
    })
      .then((r) => {
        if (r.value) {
          sermenu
            .delete(id)
            .then((response) => {
              Swal.fire({
                title: "Eliminado!",
                text: `Se ha eliminado el registro ${this.id}`,
                type: "success",
                confirmButtonClass: "md-button md-success",
                cancelButtonText: "Cancelar",
                buttonsStyling: false,
              });
              let mens = mensaje.generico(
                response.config.method,
                response.status
              );
              if (mens.accion == true) {
                this.getDatos(); // Recargar los datos después de eliminar
              }
              this.notifyVue(mens);
            })
            .catch((e) => mensaje.Error("usuario-crud-sercategorias-delete", e));
        }
        if (r.dismiss == "cancel") {
          this.Clear();
        }
      })
      .catch((e) => mensaje.Error("usuario-crud-sercategorias-delete", e));
  }
},

    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    Clear() {
      this.id = 0;
      this.nombre = null;
      this.controlador = null;
      this.pantalla = null;
      this.menuPpadreId = null;
      this.areaId = null;
      this.order = null;
    },
  },
  created() {
    this.getDatos();
    this.getArea();
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
     searchQuery(value) {
  let result = this.tableData;
  if (value !== "") {
    result = this.fuseSearch.search(value); // Cambiado a usar `value`
    console.log('Search results:', result); // Para depuración
  }
  this.searchedData = result.map(r => r.item);
},

  },
};
</script>
    
    <style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
    