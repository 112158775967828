<template>
  <div id="app">
    <h1>Tablero Kanban</h1>
    <div class="kanban-board">
      <draggable
        v-for="(column, index) in columns"
        :key="index"
        :list="column.tasks"
        class="kanban-column"
        group="tasks"
        @end="onEnd"
      >
        <div class="column-header">
          <h2>{{ column.name }}</h2>
        </div>
        <div
          class="kanban-task"
          v-for="task in column.tasks"
          :key="task.id"
          :style="getTaskStyle(task)"
        >
          {{ task.title }}
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import draggable from "vuedraggable";

export default {
  name: "App",
  components: {
    draggable,
  },
  setup() {
    const columns = ref([
      {
        name: "Pendiente",
        tasks: [
          { id: 1, title: "Diseñar el logo", color: "#FF5C58" },
          { id: 2, title: "Crear el wireframe", color: "#FFD460" },
        ],
      },
      {
        name: "En progreso",
        tasks: [{ id: 3, title: "Desarrollar el backend", color: "#6C5CE7" }],
      },
      {
        name: "Completado",
        tasks: [{ id: 4, title: "Revisión de contenido", color: "#55E6C1" }],
      },
    ]);

    const onEnd = (event) => {
      console.log("Se ha movido una tarea");
    };

    const getTaskStyle = (task) => {
      return {
        backgroundColor: task.color,
        color: "white",
      };
    };

    return {
      columns,
      onEnd,
      getTaskStyle,
    };
  },
};
</script>

<style>
/* Estilos globales */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #f4f7fa;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

#app {
  width: 100%;
  height: 100vh;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

h1 {
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Estilos del tablero */
.kanban-board {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  width: 100%;
  height: calc(100vh - 80px); /* Ajustar el tablero para que ocupe el 100% de la pantalla menos el espacio del header */
  box-sizing: border-box;
}

/* Estilos de las columnas */
.kanban-column {
  background-color: #fff;
  flex: 1; /* Las columnas ocuparán todo el ancho disponible */
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  overflow-y: auto;
  max-height: 100%;
}

.kanban-column:hover {
  transform: scale(1.02);
}

.column-header {
  background-color: #2c3e50;
  color: white;
  padding: 10px 0;
  border-radius: 10px;
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Estilos de las tareas */
.kanban-task {
  background-color: #3498db;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.kanban-task:hover {
  transform: translateY(-3px);
  background-color: #2980b9;
}

.kanban-task:active {
  transform: translateY(1px);
}

/* Para evitar scroll horizontal */
html, body {
  overflow-x: hidden;
}
</style>
