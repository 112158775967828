import { http } from "@/http-commons";

class usuario {
  all(id) {
    return http.get(`/usuario/${id}`);
  }
  usuarioJefe(){
    return http.get('/usuario');
  }
  post(usuario) {
    return http.post('/usuario',usuario);
  }
  put(id, usuario) {
    return http.put(`/usuario/${id}`,usuario);
  }
  delete(id) {
    return http.delete(`/usuario/${id}`);
  }
  getUser(id) {
    return http.get(`/usuario/user/${id}`);
  }
  getUser2(id, repId){
    return http.get(`/usuario/user2/${id}/${repId}`);
  }
  getUsuarios(id) {
    return http.get(`/usuario/userArea/${id}`);
  }
  getUsuarios2(){
    return http.get('/usuario');
  }
}

export default new usuario();