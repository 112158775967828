<template>
  <div>
    <!-- Tarjeta principal con encabezado -->
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>video_library</md-icon>
        </div>
        <h4 class="title">Subir y Visualizar Archivos</h4>
      </md-card-header>
      <md-card-content>
        <!-- Sección de carga de archivos, solo visible para areaId 1 -->
        <div class="md-layout" v-if="usuario.areaId === 1">
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <label>*Subir Archivos</label>
            <md-field>
              <md-button class="md-primary" @click="selectFiles">
                Seleccionar Archivos
              </md-button>
              <input
                type="file"
                ref="fileInput"
                multiple
                @change="handleFileUpload"
                accept="*/*"
                style="display: none"
              />
            </md-field>
          </div>
        </div>

        <!-- Contenedor con lista a la izquierda y vista a la derecha -->
        <div class="file-viewer-container">
          <!-- Lista de archivos a la izquierda -->
          <div class="file-list-container">
            <ul class="file-list">
              <li
                v-for="(file, index) in files"
                :key="index"
                class="file-item"
              >
                <!-- Muestra el nombre del archivo con botón de eliminar -->
                <div class="file-info">
                  <span>{{ file.file.name }}</span>
                  <div class="file-actions" v-if="usuario.areaId === 1">
                    <md-button
                    class="md-just-icon md-danger md-simple"
                      @click="removeFile(index)"
                    >
                      <md-icon>close</md-icon> <!-- Icono de tache en rojo -->
                    </md-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- Vista de archivos a la derecha -->
          <div class="file-preview-container">
            <div v-for="(file, index) in files" :key="index" class="file-preview">
              <div class="file-name">{{ file.file.name }}</div>
              <!-- Si es un video, muestra el video -->
              <video v-if="file.isVideo" :src="file.url" controls class="video-element"></video>
              <!-- Si es un documento, muestra el documento -->
              <iframe v-else :src="file.url" class="doc-preview"></iframe>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { openDB } from 'idb';
import store from "@/Store/index.js";

export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      files: [], // Lista de archivos cargados
      UsuariosId: 0,
    };
  },
  created() {
    this.loadFilesFromIndexedDB(); // Cargar archivos desde IndexedDB al iniciar
  },
  methods: {
    async selectFiles() {
      if (this.usuario.areaId === 1) {
        this.$refs.fileInput.click();
      } else {
        alert("No tienes permisos para subir archivos.");
      }
    },
    async handleFileUpload(event) {
      if (this.usuario.areaId === 1) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const isVideo = file.type.startsWith("video/");
          const reader = new FileReader();

          reader.onload = async (e) => {
            const fileData = e.target.result;
            const url = fileData;

            const fileObject = { name: file.name, type: file.type };
            const fileEntry = { file: fileObject, url, isVideo, fileData };

            this.files.push(fileEntry);
            await this.saveFileToIndexedDB(fileObject.name, fileEntry);
          };

          reader.readAsDataURL(file);
        }
      } else {
        alert("No tienes permisos para subir archivos.");
      }
    },
    async removeFile(index) {
      if (this.usuario.areaId === 1) {
        const fileName = this.files[index].file.name;
        this.files.splice(index, 1);
        await this.deleteFileFromIndexedDB(fileName);
      } else {
        alert("No tienes permisos para eliminar archivos.");
      }
    },
    async saveFileToIndexedDB(name, fileEntry) {
      const db = await this.openDatabase();
      await db.put('files', fileEntry, name);
    },
    async saveAllFilesToIndexedDB() {
      const db = await this.openDatabase();
      const tx = db.transaction('files', 'readwrite');
      const store = tx.objectStore('files');

      // Limpiar la base de datos antes de guardar los archivos en el nuevo orden
      const allFiles = await store.getAllKeys();
      for (const key of allFiles) {
        await store.delete(key);
      }

      // Guardar los archivos en el nuevo orden
      for (const file of this.files) {
        await store.put(file, file.file.name);
      }
      await tx.done;
    },
    async deleteFileFromIndexedDB(name) {
      const db = await this.openDatabase();
      await db.delete('files', name);
    },
    async loadFilesFromIndexedDB() {
      const db = await this.openDatabase();
      const tx = db.transaction('files', 'readonly');
      const store = tx.objectStore('files');
      
      // Obtener todas las claves en el orden en que fueron agregadas
      const allKeys = await store.getAllKeys();
      
      // Cargar archivos en el orden de las claves
      this.files = [];
      for (const key of allKeys) {
        const fileEntry = await store.get(key);
        this.files.push(fileEntry);
      }
      
      await tx.done;
    },
    async openDatabase() {
      return openDB('file-store', 1, {
        upgrade(db) {
          db.createObjectStore('files');
        },
      });
    },
  },
};
</script>

<style scoped>
.file-viewer-container {
  display: flex;
  gap: 20px; /* Espacio entre lista y vista */
}

.file-list-container {
  width: 30%; /* Anchura del contenedor de la lista */
  max-height: 600px; /* Límite de altura */
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-actions {
  display: flex;
  gap: 5px; /* Espacio entre botones */
}

/* Aumentar el tamaño del contenedor de vista previa */
.file-preview-container {
  width: 70%; /* Ajusta el ancho según sea necesario */
  max-height: 600px; /* Aumenta la altura máxima */
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Ajustar el tamaño del nombre del archivo */
.file-name {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Aumentar el tamaño de los videos */
.video-element {
  width: 100%; /* Ocupa todo el ancho del contenedor */
  max-height: 500px; /* Aumentar la altura máxima del video */
  margin-bottom: 10px;
}

.doc-preview {
  width: 100%;
  height: 500px; /* Aumentar la altura de la vista previa del documento */
  margin-bottom: 10px;
  border: none;
}

</style>
