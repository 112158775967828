import { http } from "@/http-commons";

class flujoSolicitud {
    getFlujo(id){
        return http.get(`/FlujoSolicitud/${id}`);
    }
    post(data){
        return http.post('/FlujoSolicitud', data);
    }
}

export default new flujoSolicitud();