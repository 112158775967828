<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Estatus</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>               
                <md-button class="md-icon-button md-raised md-primary mr-2" @click="getDatos()">
                  <md-icon>cached</md-icon>
                </md-button>

                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >cambiar estatus</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="Usuario"
                md-sort-by="usuarioNombreCompleto"
                >{{ item.usuarioNombreCompleto }}</md-table-cell
              >
              <md-table-cell
                md-label="Tipo de Proceso"
                md-sort-by="tipoProcesoDesc"
                >{{ item.tipoProcesoDesc }}</md-table-cell
              >
              <md-table-cell md-label="Fecha" md-sort-by="fecha">{{
                item.fecha
              }}</md-table-cell>
              <md-table-cell
                md-label="Observaciones"
                md-sort-by="observacion"
                >{{ item.observacion }}</md-table-cell
              >
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
         <md-card-actions md-alignment="space-between">
      </md-card-actions>
      </md-card>
      
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label for="tipoProcesoId">Tipo Proceso</label>
            <md-select v-model="tipoProcesoId">
              <md-option
                v-for="a in tipoProcesoCol"
                :key="a.tipoProcesoId"
                :value="a.tipoProcesoId"
              >
                {{ a.descripcion }}
              </md-option>
            </md-select>
          </md-field>
          <md-field>
            <label>Observaciones</label>
            <md-input v-model="observacion"></md-input>
            <span class="md-helper-text">observacion</span>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servEstatus from "@/Servicios/Operacion/estatus";
import store from "@/Store/index";
import servTipoProceso from "@/Servicios/Catalogos/tipoproceso";
export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /*
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "usuariosSolicitudId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      //footerTable: ["Id", "Descripción", "Dashboard", "Acciones"],
      searchQuery: "",
      propsToSearch: [
        "usuariosSolicitudId",
        "usuariosId",
        "solicitudId",
        "fecha",
        "observacion",
        "tipoProcesoId",
        "usuarioNombreCompleto",
        "tipoProcesoDesc",
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      usuariosSolicitudId: 0,
      usuariosId: 0,
      solicitudId: 0,
      fecha: null,
      observacion: null,
      tipoProcesoId: 0,
      usuarioNombreCompleto: null,
      tipoProcesoDesc: null,
      datosModal: {
        titulo: "Crear una área",
        boton: "Confirmar",
        evento: 0,
      },
      tipoProcesoCol: [],
    };
  },
  methods: {
    getTipoProceso() {
      servTipoProceso
        .all()
        .then((r) => {
          this.tipoProcesoCol = r.data;
        })
        .catch((e) => console.log("Error en cargar combo tipo proceso" + e));
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      let today = new Date();
      var data = {
        usuariosSolicitudId: this.usuariosSolicitudId,
        usuariosId: this.usuario.usuariosId,
        solicitudId: this.general.SolicitudId,
        fecha: today,
        tipoProcesoId: this.tipoProcesoId,
        observacion: this.observacion,
        usuarioNombreCompleto: this.usuario.usuarioNomCompleto,
      };
      switch (exp) {
        case 1:
          servEstatus
            .post(data)
            .then((r) => {
              this.ProcesamosResponse(r.data);
            })
            .catch(function (e) {
              mensaje.Error("estatus-crud-post", e);
            });
          break;
      }
    },
    getDatos() {
      servEstatus
        .getId(this.general.SolicitudId)
        .then((a) => {
          this.tableData = a.data;
          // console.log(this.tableData);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: [
              "usuariosSolicitudId",
              "usuariosId",
              "solicitudId",
              "fecha",
              "observacion",
              "tipoProcesoId",
              "usuarioNombreCompleto",
              "tipoProcesoDesc",
            ],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("estatus-getid", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Cambiar Estatus";
          this.datosModal.boton = "Guardar";
          this.datosModal.evento = 1;
          break;
      }
    },
    Regresamos() {
      this.$router.push("../solicituds");
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      this.usuariosSolicitudId = 0;
      this.usuariosId = 0;
      this.solicitudId = 0;
      this.fecha = null;
      this.observacion = null;
      this.tipoProcesoId = 0;
      this.usuarioNombreCompleto = null;
      this.tipoProcesoDesc = null;
    },
    ProcesamosResponse(data) {
      //REALIZA EL PROCESO DE LOS MENSAJES DESDE EL CONTROLLER
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            this.notifyVue(m);
            break;
          case 3: //success
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.getDatos();
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
  },
  created() {
    if (this.general.SolicitudId > 0) {
      this.getDatos();
      this.getTipoProceso();
    }
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      console.log("this.tableData", this.tableData);
      if (value !== "") {
        // console.log(value);
        // console.log(this.searchQuery);
        //console.log(this.fuseSearch);
        result = this.fuseSearch.search(this.searchQuery);
        console.log("result", result);
      }
      this.searchedData = result.map((i) => {
        return i.item;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
.mr-2 {
  margin-right: 0.5rem; /* Ajusta este valor a tus necesidades */
}
</style>