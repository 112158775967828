<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>mail_outline</md-icon>
          </div>
          <h4 class="title">Arrendatario - Fisica</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>*Nombre(s)</label>
                    <label style="color: brown !important;" v-if="isBandera">*Nombre(s)</label>
                    <md-input
                      v-model="sfisicaNombre"
                     
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>*Apellido Paterno</label>
                    <label style="color: brown !important;" v-if="isBandera">*Apellido Paterno</label>
                    <md-input
                      v-model="sfisicaApePat"
               
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>*Apellido Materno</label>
                    <label style="color: brown !important;" v-if="isBandera">*Apellido Materno</label>
                    <md-input
                      v-model="sfisicaApeMat"
                
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>RFC</label>
                    <md-input
                      v-model="sfisicaRfc"
                
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>*Nacionalidad</label>
                    <label style="color: brown !important;" v-if="isBandera">*Nacionalidad</label>
                    <md-input
                      v-model="sfisicaNacionallidad"
         
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Condición Migratoria</label>
                    <md-input
                      v-model="sfisicaCondMigratoria"
                   
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Estado Civil</label>
                    <md-input
                      v-model="sfisicaEstadoCivil"
                   
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Convenio</label>
                    <md-input
                      v-model="sfisicaConvenioEc"
                     
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-medium-size-100">
                  <md-field>
                    <label>Tipo de Identificación</label>
                    <md-input
                      v-model="tipoIdentificacion"
                    
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-medium-size-100">
                  <md-field>
                    <label>Número de Identificación</label>
                    <md-input
                      v-model="numeroIdentificacion"
                     
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-medium-size-100">
                  <md-field>
                    <label>*Domicilio</label>
                    <label style="color: brown !important;" v-if="isBandera">*Domicilio</label>
                    <md-input
                      v-model="sfisicaDomicilio"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50 md-medium-size-100">
                  <md-field>
                    <label>*Colonia</label>
                    <label style="color: brown !important;" v-if="isBandera">*Colonia</label>
                    <md-input
                      v-model="sfisicaColonia"
                      
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>*Delegación / Municipio</label>
                    <label style="color: brown !important;" v-if="isBandera">*Delegación / Municipio</label>
                    <md-input
                      v-model="sfisicaDelegacionMunicipio"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>*Código Postal</label>
                    <label style="color: brown !important;" v-if="isBandera">*Código Postal</label>
                    <md-input
                      v-model="sfisicaCodigoPostal"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-33 md-medium-size-100">
                  <md-field>
                    <label>*Estado</label>
                    <label style="color: brown !important;" v-if="isBandera">*Estado</label>
                    <md-input
                      v-model="sfisicaEstado"
                     
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Teléfono</label>
                    <md-input
                      v-model="sfisicaTelefono"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>*Celular</label>
                    <label style="color: brown !important;" v-if="isBandera">*Celular</label>
                    <md-input
                      v-model="sfisicaCelular"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>*Email</label>
                    <label style="color: brown !important;" v-if="isBandera">*Email</label>
                    <md-input
                      v-model="sfisicaEmail"
                      
                      type="email"
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Profesión</label>
                    <md-input
                      v-model="sfisicaProfesion"
                      
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Ingreso Mensual</label>
                    <md-input
                      v-model="sfisicaIngresoMensual"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Empresa donde trabaja</label>
                    <md-input
                      v-model="sfisicaTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Antiguedad</label>
                    <md-input
                      v-model="sfisicaAntiguedad"
                      
                      type="email"
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Puesto</label>
                    <md-input
                      v-model="sfisicaPuesto"
                      
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Teléfono Trabajo</label>
                    <md-input
                      v-model="sfisicaTelefonoTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Horario</label>
                    <md-input
                      v-model="sfisicaHorario"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Giro</label>
                    <md-input
                      v-model="sfisicaGiroTrabajo"
                      
                      type="email"
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Sitio Web</label>
                    <md-input
                      v-model="sfisicaWebTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-20 md-medium-size-100">
                  <md-field>
                    <label>Domicilio del Trabajo</label>
                    <md-input
                      v-model="sfisicaDomicilioTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-20 md-medium-size-100">
                  <md-field>
                    <label>Colonia</label>
                    <md-input
                      v-model="sfisicaColoniaTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-20 md-medium-size-100">
                  <md-field>
                    <label>Alcaldía/Municipio</label>
                    <md-input
                      v-model="sfisicaDelegMuniTrabajo"
                      
                      type="email"
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-20 md-medium-size-100">
                  <md-field>
                    <label>Código Postal </label>
                    <md-input
                      v-model="sfisicaCodigoPostalTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-20 md-medium-size-100">
                  <md-field>
                    <label>Estado</label>
                    <md-input
                      v-model="sfisicaEstadoTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout">
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Jefe</label>
                    <md-input
                      v-model="sfisicaJefeTrabajo"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Puesto</label>
                    <md-input
                      v-model="sfisicaPuestoJefe"
                      
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-field>
                    <label>Email Jefe</label>
                    <md-input
                      v-model="sfisicaEmailJefe"
                      
                      type="email"
                    ></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-25 md-medium-size-100">
                  <md-checkbox
                    v-model="sfisicaFactura"
                    
                    >¿Requiere de comprobante fiscal para el
                    arrendamiento?</md-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button v-if="this.visible == false" class="md-primary"  @click.native="postNow()">Guardar</md-button>
          <md-button v-if="this.visible == true" class="md-primary" disabled>
                <div class="loader"></div>
          </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>

import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import store from "../../Store/index";
import servFM from "../../Servicios/Operacion/fisicamolal.js";
export default {
  components: {},
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
  },
  data() {
    return {
      sfisicaMoralid:0,
      solicitudId:0,
      sfisicaNombre: "",
      sfisicaApePat: "",
      sfisicaApeMat: "",
      sfisicaRfc: "",
      sfisicaNacionallidad: "",
      sfisicaCondMigratoria: "",
      sfisicaEstadoCivil: "",
      sfisicaConvenioEc: "",
      tipoIdentificacion: "",
      numeroIdentificacion: "",
      sfisicaDomicilio: "",
      sfisicaColonia: "",
      sfisicaDelegacionMunicipio: "",
      sfisicaCodigoPostal: "",
      sfisicaEstado: "",
      sfisicaTelefono: "",
      sfisicaCelular: "",
      sfisicaEmail: "",
      sfisicaProfesion: "",
      sfisicaIngresoMensual: 0.00,
      sfisicaTrabajo: "",
      sfisicaAntiguedad: "",
      sfisicaPuesto: "",
      sfisicaTelefonoTrabajo: "",
      sfisicaHorario: "",
      sfisicaGiroTrabajo: "",
      sfisicaWebTrabajo: "",
      sfisicaDomicilioTrabajo: "",
      sfisicaColoniaTrabajo: "",
      sfisicaDelegMuniTrabajo:"",
      sfisicaCodigoPostalTrabajo:"",
      sfisicaEstadoTrabajo:"",
      sfisicaJefeTrabajo: "",
      sfisicaPuestoJefe: "",
      sfisicaEmailJefe: "",
      sfisicaFactura: false,
      isBandera : false,
      visible : false,
    };
  },
  methods: {
    getDatos(id) {
      servFM
        .getId(id)
        .then((r) => this.Asignamos(r.data))
        .catch((e) => console.log("Error - fisicaMoral", e));
    },  
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    Asignamos(r) {
      this.sfisicaNombre = r.sfisicaNombre;
      this.sfisicaApePat = r.sfisicaApePat;
      this.sfisicaApeMat = r.sfisicaApeMat;
      this.sfisicaRfc = r.sfisicaRfc;
      this.sfisicaNacionallidad = r.sfisicaNacionallidad;
      this.sfisicaCondMigratoria = r.sfisicaCondMigratoria;
      this.sfisicaEstadoCivil = r.sfisicaEstadoCivil;
      this.sfisicaConvenioEc = r.sfisicaConvenioEc;
      this.tipoIdentificacion = r.tipoIdentificacion;
      this.numeroIdentificacion = r.numeroIdentificacion;
      this.sfisicaDomicilio = r.sfisicaDomicilio;
      this.sfisicaColonia = r.sfisicaColonia;
      this.sfisicaDelegacionMunicipio = r.sfisicaDelegacionMunicipio;
      this.sfisicaCodigoPostal = r.sfisicaCodigoPostal;
      this.sfisicaEstado = r.sfisicaEstado;
      this.sfisicaTelefono = r.sfisicaTelefono;
      this.sfisicaCelular = r.sfisicaCelular;
      this.sfisicaEmail = r.sfisicaEmail;
      this.sfisicaProfesion = r.sfisicaProfesion;
      this.sfisicaIngresoMensual = r.sfisicaIngresoMensual;
      this.sfisicaTrabajo = r.sfisicaTrabajo;
      this.sfisicaAntiguedad = r.sfisicaAntiguedad;
      this.sfisicaPuesto = r.sfisicaPuesto;
      this.sfisicaTelefonoTrabajo = r.sfisicaTelefonoTrabajo;
      this.sfisicaHorario = r.sfisicaHorario;
      this.sfisicaGiroTrabajo = r.sfisicaGiroTrabajo;
      this.sfisicaWebTrabajo = r.sfisicaWebTrabajo;
      this.sfisicaDomicilioTrabajo = r.sfisicaDomicilioTrabajo;
      this.sfisicaColoniaTrabajo = r.sfisicaColoniaTrabajo;
      this.sfisicaDelegMuniTrabajo=r.sfisicaDelegMuniTrabajo;
      this.sfisicaCodigoPostalTrabajo=r.sfisicaCodigoPostalTrabajo;
      this.sfisicaEstadoTrabajo=r.sfisicaEstadoTrabajo;
      this.sfisicaJefeTrabajo = r.sfisicaJefeTrabajo;
      this.sfisicaPuestoJefe = r.sfisicaPuestoJefe;
      this.sfisicaEmailJefe = r.sfisicaEmailJefe;
      this.sfisicaFactura = r.sfisicaFactura;
    },
    postNow(){
      this.visible = true;
      this.solicitudId = this.general.SolicitudId;
       var data = {
        //IDS
        SolicitudId: this.solicitudId,
        FisicaMoralId: this.sfisicaMoralid,
        //FISICA MORAL 
        SFisicaNacionallidad: this.sfisicaNacionallidad,
        SFisicaCondMigratoria: this.sfisicaCondMigratoria ,
        SFisicaEstadoCivil:this.sfisicaEstadoCivil,
        SFisicaConvenioEC: this.sfisicaConvenioEc , 
        SFisicaDomicilio: this.sfisicaDomicilio ,
        SFisicaColonia: this.sfisicaColonia ,
        SFisicaDelegacionMunicipio: this.sfisicaDelegacionMunicipio ,
        SFisicaEstado: this.sfisicaEstado ,
        SFisicaTelefono: this.sfisicaTelefono ,
        SFisicaCelular: this.sfisicaCelular,
        SFisicaEmail: this.sfisicaEmail ,
        SFisicaProfesion: this.sfisicaProfesion,
        SFisicaIngresoMensual: this.sfisicaIngresoMensual ,
        SFisicaTrabajo: this.sfisicaTrabajo,
        SFisicaAntiguedad: this.sfisicaAntiguedad,
        SFisicaPuesto: this.sfisicaPuesto,
        SFisicaTelefonoTrabajo: this.sfisicaTelefonoTrabajo ,
        SFisicaHorario: this.sfisicaHorario ,
        SFisicaDomicilioTrabajo: this.sfisicaDomicilioTrabajo ,
        SFisicaColoniaTrabajo: this.sfisicaColoniaTrabajo ,
        SFisicaDelegMuniTrabajo: this.sfisicaDelegMuniTrabajo ,
        SFisicaEstadoTrabajo: this.sfisicaEstadoTrabajo ,
        SFisicaGiroTrabajo:this.sfisicaGiroTrabajo ,
        SFisicaWebTrabajo: this.sfisicaWebTrabajo ,
        SFisicaJefeTrabajo: this.sfisicaJefeTrabajo ,
        SFisicaPuestoJefe: this.sfisicaPuestoJefe ,
        SFisicaEmailJefe: this.sfisicaEmailJefe ,
        SFisicaFactura: this.sfisicaFactura ,
        SFisicaNombre:this.sfisicaNombre ,
        SFisicaApePat: this.sfisicaApePat ,
        SFisicaApeMat:this.sfisicaApeMat ,
        SFisicaRFC:this.sfisicaRfc ,
        //SFisicaRazonSocial:this.SFisicaRazonSocial ,
        SFisicaCodigoPostal: this.sfisicaCodigoPostal ,
        SFisicaCodigoPostalTrabajo: this.sfisicaCodigoPostalTrabajo ,
        TipoIdentificacion:this.tipoIdentificacion ,
        NumeroIdentificacion:this.numeroIdentificacion,
        //
       };
      var dataActual = {
        //IDS
        SolicitudId: this.general.SolicitudId,
        FisicaMoralId: this.general.FisicaMoralId,
        //FISICA MORAL 
        SFisicaNacionallidad: this.sfisicaNacionallidad,
        SFisicaCondMigratoria: this.sfisicaCondMigratoria ,
        SFisicaEstadoCivil:this.sfisicaEstadoCivil,
        SFisicaConvenioEC: this.sfisicaConvenioEc , 
        SFisicaDomicilio: this.sfisicaDomicilio ,
        SFisicaColonia: this.sfisicaColonia ,
        SFisicaDelegacionMunicipio: this.sfisicaDelegacionMunicipio ,
        SFisicaEstado: this.sfisicaEstado ,
        SFisicaTelefono: this.sfisicaTelefono ,
        SFisicaCelular: this.sfisicaCelular,
        SFisicaEmail: this.sfisicaEmail ,
        SFisicaProfesion: this.sfisicaProfesion,
        SFisicaIngresoMensual: this.sfisicaIngresoMensual ,
        SFisicaTrabajo: this.sfisicaTrabajo,
        SFisicaAntiguedad: this.sfisicaAntiguedad,
        SFisicaPuesto: this.sfisicaPuesto,
        SFisicaTelefonoTrabajo: this.sfisicaTelefonoTrabajo ,
        SFisicaHorario: this.sfisicaHorario ,
        SFisicaDomicilioTrabajo: this.sfisicaDomicilioTrabajo ,
        SFisicaColoniaTrabajo: this.sfisicaColoniaTrabajo ,
        SFisicaDelegMuniTrabajo: this.sfisicaDelegMuniTrabajo ,
        SFisicaEstadoTrabajo: this.sfisicaEstadoTrabajo ,
        SFisicaGiroTrabajo:this.sfisicaGiroTrabajo ,
        SFisicaWebTrabajo: this.sfisicaWebTrabajo ,
        SFisicaJefeTrabajo: this.sfisicaJefeTrabajo ,
        SFisicaPuestoJefe: this.sfisicaPuestoJefe ,
        SFisicaEmailJefe: this.sfisicaEmailJefe ,
        SFisicaFactura: this.sfisicaFactura ,
        SFisicaNombre:this.sfisicaNombre ,
        SFisicaApePat: this.sfisicaApePat ,
        SFisicaApeMat:this.sfisicaApeMat ,
        SFisicaRFC:this.sfisicaRfc ,
        //SFisicaRazonSocial:this.SFisicaRazonSocial ,
        SFisicaCodigoPostal: this.sfisicaCodigoPostal ,
        SFisicaCodigoPostalTrabajo: this.sfisicaCodigoPostalTrabajo ,
        TipoIdentificacion:this.tipoIdentificacion ,
        NumeroIdentificacion:this.numeroIdentificacion,
        //
      }
       if(this.general.FisicaMoralId > 0){
        servFM
       .put(this.general.FisicaMoralId, dataActual)
       .then((reponse) => {
          this.procesamosResponse(reponse.data);
       })
       .catch((e)=>{
        let m = {
          mensaje: "",
          tipo: "",
          };
        this.isBandera = true;
        m.mensaje = "Ocurrio un error verifica los campos en rojo"
        m.tipo = "danger";     
        this.notifyVue(m);
      })
      }
      else{
        this.general.FisicaMoralId = this.sfisicaMoralid;
        servFM
       .post(data)
       .then((reponse) => {
          this.procesamosResponse(reponse.data);
       })
       .catch((e)=>{
        let m = {
          mensaje: "",
          tipo: "",
          };
        this.isBandera = true;
        m.mensaje = "Ocurrio un error verifica los campos en rojo"
        m.tipo = "danger";     
        this.notifyVue(m);
      })
      }

    },
    procesamosResponse(data){
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 1: //Error
            m.mensaje = e.mensaje;         
            m.tipo = "danger";
            this.isBandera = true;
            this.notifyVue(m);
            this.visible = false;
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);  
            this.visible = false;      
            if(this.general.FisicaMoralId == 0){
              this.$router.push('solicituds');
            }         
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
  },
  created(){
    if (this.general.SolicitudId > 0) {
      this.getDatos(this.general.SolicitudId);
    }
    console.log("ID fisicamoral:", this.general.FisicaMoralId);
  },
  mounted() {

  },
};
</script>
<style lang="scss" scoped>
.loader {
  width: 90px;
  height: 20px;
  font-size: 12px;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Guardando....."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}
</style>
