<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Eventos - Pláticas</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(6)"
                  >Restringir correos</md-button
                >
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-progress-bar
              class="md-warning"
              md-mode="buffer"
              v-if="isLoader == true"
            ></md-progress-bar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="eventosId">{{
                item.eventosId
              }}</md-table-cell>
              <md-table-cell md-label="Titulo" md-sort-by="titulo">{{
                item.titulo
              }}</md-table-cell>
              <md-table-cell md-label="Descripción" md-sort-by="direccion">{{
                item.descripcion
              }}</md-table-cell>
              <md-table-cell md-label="Lugar" md-sort-by="lugar">{{
                item.lugar
              }}</md-table-cell>
              <md-table-cell
                md-label="Fecha y Hora de Inicio"
                md-sort-by="fechaHoraInicio"
                >{{ item.fechaHoraInicio }}</md-table-cell
              >
              <md-table-cell
                md-label="Fecha y Hora de Fin"
                md-sort-by="fechaHoraFin"
                >{{ item.fechaHoraFin }}</md-table-cell
              >
              <md-table-cell md-label="activo" md-sort-by="activo">{{
                item.activo
              }}</md-table-cell>
              <md-table-cell md-label="Acciones" style="width: 100px">
                <md-button
                 
                  style="width: 50px"
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  
                  style="width: 50px"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
                <md-button
             

                  style="width: 50px"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(4, item)"
                >
                  <md-icon>image</md-icon>
                </md-button>
                <md-button
                  style="width: 50px"
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(5, item)"
                >
                  <md-icon>mail</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template
          slot="body"
          v-if="
            datosModal.evento == 1 ||
            datosModal.evento == 2 ||
            datosModal.evento == 5
          "
        >
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label>Id</label>
                <md-input v-model="eventosId" disabled></md-input>
                <span class="md-helper-text">Id</span> 
              </md-field>
              <md-field :class="tituloError">
                <label>Titulo</label>
                <!--<md-input v-model="titulo" type="email" :disabled="Inputs"></md-input>-->
                <md-input v-model="titulo" type="email"></md-input>
                <span class="md-helper-text">titulo</span>
              </md-field>
              <md-field :class="descripcionError">
                <label>Descripción</label>
                <!--<md-input v-model="descripcion" :disabled="Inputs"></md-input>-->
                <md-input v-model="descripcion"></md-input>
                <span class="md-helper-text">descripción</span>
              </md-field>
              <md-field :class="lugarError">
                <label>Lugar</label>
                <!--<md-input v-model="lugar" :disabled="Inputs"></md-input>-->
                <md-input v-model="lugar"></md-input>
                <span class="md-helper-text">lugar</span>
              </md-field>
              <label>Fecha y hora de inicio</label>
              <md-field :class="fechaHoraInicioError">
              <md-input v-model="fechaHoraInicio" type="datetime-local"></md-input>
              <!--<md-input v-model="fechaHoraInicio" type="datetime-local" :disabled="Inputs"></md-input>-->
              </md-field>
              <label>Fecha y hora de Fin</label>
              <md-field :class="fechaHoraFinError">
                <md-input v-model="fechaHoraFin" type="datetime-local"></md-input>
                <!--<md-input v-model="fechaHoraFin" type="datetime-local" :disabled="Inputs"></md-input>-->
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field :class="activoError">
                <md-checkbox v-model="activo">Activo</md-checkbox>
                <!--<md-checkbox v-model="activo" :disabled="Inputs">Activo</md-checkbox>-->
              </md-field>
              <md-field :class="reunionIdeError">
                <label>Id de la reunión</label>
                <md-input v-model="reunionIde"></md-input>
                <!--<md-input v-model="reunionIde" :disabled="Inputs"></md-input>-->
                <span class="md-helper-text">Id de la reunión</span>
              </md-field>
              <md-field :class="reunionUrError">
                <label>URL de la reunión</label>
                <md-input v-model="reunionUr"></md-input>
                <!--<md-input v-model="reunionUr" :disabled="Inputs"></md-input>-->
                <span class="md-helper-text">URL de la reunión</span>
              </md-field>
              <md-field :class="reunionPassError">
                <label>Contraseña de la reunión</label>
                <md-input v-model="reunionPass"></md-input>
                <!--<md-input v-model="reunionPass" :disabled="Inputs"></md-input>-->
                <span class="md-helper-text">Contraseña de la reunión</span>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="body" v-if="datosModal.evento == 4">
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <div class="file-input">
                  <div v-if="!imageRegular">
                    <div class="image-container">
                      <img :src="regularImg" title="" />
                    </div>
                  </div>
                  <div class="image-container" v-else>
                    <img :src="imageRegular" />
                  </div>
                  <div class="button-container">
                    <md-button
                      class="md-danger md-round"
                      @click="removeImage"
                      v-if="imageRegular"
                      ><i class="fa fa-times"></i>Eliminar</md-button
                    >
                    <md-button class="md-success md-round md-fileinput">
                      <template v-if="!imageRegular">Select image</template>
                      <template v-else>Cambiar</template>
                      <input type="file" @change="onFileChange" />
                    </md-button>
                  </div>
                </div>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="createOrUpdateOrDelete(datosModal.evento)"
            :disabled="isActivo"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
      <modal v-if="classicModal2" @close="classicModalHide2">
        <template slot="header">
          <h4 class="modal-title">Agregar Correos Restringidos</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide2"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template
          slot="body"
        >
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label>Id</label>
                <md-input v-model="eventosId" disabled></md-input>
                <span class="md-helper-text">Id</span> 
              </md-field>
              <md-field :class="tituloError">
                <label>Correo que no quieren recibir Emails</label>
                <!--<md-input v-model="titulo" type="email" :disabled="Inputs"></md-input>-->
                <md-input v-model="correo" type="email"></md-input>
                <span class="md-helper-text">Correo</span>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide2"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="createOrUpdateOrDelete(2)"
            :disabled="isActivo"
            >Agregar</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import serveventos from "../../Servicios/Representacion/eventos.js";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servicioStorage from "@/Utilerias/serviceStorage";
import store from "@/Store/index";
export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
     general() {
      return store.state.General;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      imageRegular: "",
      currentSort: "eventosId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      area: {
         areaId: 3, // Supongamos que aquí obtienes el rol del usuario actual
         },
      footerTable: [
        "eventosId",
        "titulo",
        "descripcion",
        "lugar",
        "fechaHoraInicio",
        "fechaHoraFin",
        "activo",
        "Acciones",
      ],
      searchQuery: "",
      propsToSearch: ["eventosId", "titulo"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      classicModal2 : false,
      Id: 0,
      datosModal: {
        titulo: "Crear un evento",
        boton: "Confirmar",
        evento: 0,
      },
      eventosId: 0,
      titulo: null,
      descripcion: null,
      lugar: null,
      fechaHoraInicio: null,
      fechaHoraFin: null,
      activo: 0,
      reunionIde: 0,
      reunionPass: "",
      imagen: "",
      reunionUr: null,
      isActivo: false,
      eventosIdError: 0,
      tituloError: null,
      descripcionError: null,
      lugarError: null,
      fechaHoraInicioError: null,
      fechaHoraFinError: 0,
      activoError: 0,
      reunionIdeError: 0,
      reunionPassError: "",
      imagenError: null,
      reunionUrError: null,
      Eventos: [],
      regularImg: null,
      FiltroEventoId: 0,
      Evento: null,
      Inputs: true,
      filename: "",
      file: null,
      correo :  "",
      correoId : 0,
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    async createOrUpdateOrDelete(exp) {
      console.log("Entro", exp);
      this.isActivo = true;
      var isBAndera = false;
      console.log("data", dataCorreo); 
      if (this.titulo == null && (exp == 1 || exp == 4)) {
        let mens = {
          tipo: "danger",
          mensaje: "El titulo del evento no puede estar vacío.",
        };
        this.notifyVue(mens);
        isBAndera = true;
        this.isActivo = false;
      }
      if (exp == 4 && this.imagen == null) {
        let mens = {
          tipo: "danger",
          mensaje: "Favor de seleccionar una imagen",
        };
        this.notifyVue(mens);
        isBAndera = true;
        this.isActivo = false;
      }
      if (isBAndera == false && (exp == 1 || exp == 2)) {
        var dataCorreo = {
           CorreoId : this.correoId,
           Correo : this.correo,
         };
        var data = {
          eventosId: this.eventosId,
          titulo: this.titulo,
          descripcion: this.descripcion,
          lugar: this.lugar,
          fechaHoraInicio: this.fechaHoraInicio,
          fechaHoraFin: this.fechaHoraFin,
          activo: this.activo,
          reunionIde: this.reunionIde,
          reunionPass: this.reunionPass,
          imagen: this.imagen,
          reunionUr: this.reunionUr,
          isActivo: this.isActivo,
        };
       
      }
      else {
        let pathS3 = await this.uploadFileS3();
        var dataimage = {
          eventosId: this.eventosId,
          titulo: this.titulo,
          descripcion: this.descripcion,
          lugar: this.lugar,
          fechaHoraInicio: this.fechaHoraInicio,
          fechaHoraFin: this.fechaHoraFin,
          activo: this.activo,
          reunionIde: this.reunionIde,
          reunionPass: this.reunionPass,
          imagen: pathS3,
          reunionUr: this.reunionUr,
          isActivo: this.isActivo,
        };
      }

      switch (exp) {
        case 1:
          if (isBAndera == false) {
            data.eventosId = 0;
            serveventos
              .post(data)
              .then((r) => {
                //hay que cerrar el model
                this.ProcesamosResponse(r.data);
                //Aqui recuperamos los datos
              })
              .catch((e) => {
                this.classicModalHide();
                mensaje.Error("representaciones-crud-serveventos-post", e);
              });
          }
          break;
        case 2:
          serveventos 
           .postCorreo(dataCorreo)
           .then((r) => {
             this.ProcesamosResponse(r.data);
           })
        case 4:
          if (this.eventosId > 0 && isBAndera == false) {
            serveventos
              .put(this.eventosId, dataimage)
              .then((r) => {
                //hay que cerrar el modal
                this.ProcesamosResponse(r.data);
                //Aqui recuperamos los datos
              })
              .catch((e) => {
                this.classicModalHide();
                mensaje.Error("representaciones-crud-serveventos-put", e);
              });
          }

          break;
        case 5:
          console.log("entramos y Id", this.eventosId);
          if (this.eventosId > 0) {
            console.log("entramos");
            serveventos
              .Notificacionusuariosdelevento(this.eventosId)
              .then((r) => {
                //hay que cerrar el model
                this.ProcesamosResponse(r.data);
               // console.log(r);
                //Aqui recuperamos los datos
              })
              .catch((e) => {
                this.classicModalHide();
                mensaje.Error("representaciones-crud-serveventos-Notificacionusuariosdelevento", e);
              });
          }
          break;
      }
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.isActivo = false;
            this.notifyVue(m);
            break;
          case 3: //success
            this.isActivo = false;
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getDatos() {
      this.isLoader = true;
      this.tableData = [];
      serveventos
        .all()
        .then((p) => {
          this.tableData = p.data;
          this.isLoader = false;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["eventosId", "titulo", "descripcion"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-crud-all", e);
          this.isLoader = false;
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    classicModalHide2() {
      this.Clear();
      this.classicModal2 = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Crear una representación";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.RetriveOnlyData(row);
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar evento";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.eventosId = row.eventosId;
          this.deleteRow();
          break;
        case 4: //Imagen
          this.RetriveOnlyData(row);
          this.classicModal = true;
          this.datosModal.titulo = "Agregar / Actualizar Imagen";
          this.datosModal.boton = "Agregar";
          this.datosModal.evento = 4;
          if (row.imagen != null) {
            this.imagen = row.imagen;
          }
          break;
        case 5:
          this.Inputs = true;
          this.classicModal = true;
          this.RetriveOnlyData(row);
          this.eventosId = row.eventosId;
          this.Evento = row;
          this.datosModal.titulo = "Notificación masiva por E-mail";
          this.datosModal.boton = "Enviar Email Masivo";
          this.datosModal.evento = 5;
          break;
        case 6: 
        this.classicModal2 = true;

      }
    },
    deleteRow() {
      if (this.eventosId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.eventosId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              serveventos
                .delete(this.eventosId)
                .then((response) => {
                  this.getDatos();
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.eventosId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      this.Inputs = false;
      this.eventosId = 0;
      this.titulo = null;
      this.descripcion = null;
      this.lugar = null;
      this.fechaHoraInicio = null;
      this.fechaHoraFin = null;
      this.activo = null;
      this.reunionIde = null;
      this.reunionPass = null;
      this.imagen = null;
      this.reunionUr = null;
      this.isActivo = null;
      this.correo = "";
    },
    async uploadFileS3() {
      if (this.file != null) {
        //Eliminamos espacios en blanco en el nombre del archivo
        let nameFile = this.file.name.replace(/\s/g, "");
        //Aqui falta agregar la ruta correcta de S3 para guardar la imagen ene l bucket
        let pathS3 = this.SolicitudId + "/" + "Solucion/" + nameFile;
        return await servicioStorage
          .upload(pathS3, this.file, this.file.type)
          .then((r) => {
            return r.Location;
          })
          .catch(function (e) {
            mensaje.Error("Solucion-crud-post-servStorage", e);
            return null;
          });
      } else {
        let mensaje = {
          mensaje: "No se ha seleccionado una imagen",
          tipo: "warning",
        };
        this.notifyVue(mensaje);
      }
    },
    async onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file = files[0];
      if (e.target.name) {
        this.createImage(files[0], "circle");
      } else {
        this.createImage(files[0]);
      }
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageRegular = e.target.result;
        }
        this.imagen = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageRegular = "";
      }
    },
    LoadEventosComboBox() {
      this.Eventos = [];
      var hoy = Date.now();
      this.tableData.forEach((e) => {
        var fecha = new Date();
        fecha = Date.parse(e.fechaHoraInicio);
        if (e.activo == true && fecha >= hoy) {
          let evento = {
            Id: e.eventosId,
            nombre: e.titulo + " " + e.fechaHoraInicio,
          };
          this.Eventos.push(evento);
        }
      });
      //console.log(this.Eventos);
    },
    RetriveOnlyData(row) {
      this.eventosId = row.eventosId;
      this.titulo = row.titulo;
      this.descripcion = row.descripcion;
      this.lugar = row.lugar;
      this.fechaHoraInicio = row.fechaHoraInicio;
      this.fechaHoraFin = row.fechaHoraFin;
      this.activo = row.activo;
      this.reunionIde = row.reunionIde;
      this.reunionPass = row.reunionPass;
      this.imagen = row.imagen;
      this.reunionUr = row.reunionUr;
      this.isActivo = row.isActivo;
    },
  },
  created() {
    this.getDatos();
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },

};
</script>

<style lang="css" scoped>
</style>
