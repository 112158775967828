<template>
  <div>
    <div v-if="this.usuario.representacionId == 24" class="header text-center">
      <div class="md-layout header text-center">
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-60">
      <div class="md-layout-item md-medium-size-100">
        <md-card>
          <md-card-content>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <label>Seleccionar Representacion </label>
                    <md-field>
                    <md-select v-model="representacionId" multiple>
                      <md-option
                        v-for="r in tableRepresentacion"
                        :key="r.representacionId"
                        :value="r.representacionId"
                      >
                        {{ r.representacionNombre }}
                      </md-option>
                    </md-select>
                  </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                      <span>Fecha Inicio</span>
                      <md-field>
                          <md-input v-model="fechaInicio" type="date" ></md-input>                        
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                      <span>Fecha Fin</span>
                      <md-field>
                          <md-input v-model="fechaFin" type="date" ></md-input>                        
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-100 md-medium-size-100">
                    <md-button style="margin-top: 18px" class="md-primary" @click.native="Events()"
                        >Consultar</md-button
                      >
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          </md-card>
         </div> 
       </div>
    </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <md-card class="md-card-calendar">
          <md-card-content>
            <fullCalendar ref="calendar" :options="calendarOptions" />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <modal v-if="classicModal == true" @close="classicModalHide">
      <template slot="body">
        <md-field>
          <label>Id</label>
          <md-input v-model="CalendarioId" disabled></md-input>
          <span class="md-helper-text">Id</span>
        </md-field>
        <md-field>
          <label>Seleccionar Tipo Evento</label>
          <md-select :disabled="this.modo == 5" v-model="CalendarioDescripcion">
            <md-option value="Fecha Tentativa de Firma"
              >Fecha Tentativa de Firma</md-option
            >
            <md-option value="Entrega Inmueble">Entrega Inmueble</md-option>
            <md-option value="Juzgado">Juzgado</md-option>
            <md-option value="Recabar Firmas">Recabar Firmas</md-option>
            <md-option value="Platica Publica">Platica Publica</md-option>
            <md-option value="Platica Interna">Platica Interna</md-option>
            <md-option value="Vacaciones">Vacaciones</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>Fecha</label>
          <md-input v-model="CalendarioFechaFirma" type="datetime-local"></md-input>
          <span class="md-helper-text">Fecha</span>
        </md-field>
        <md-field v-if="CalendarioDescripcion == 'Vacaciones'">
          <label>Fecha Fin</label>
          <md-input v-model="CalendarioFechaFirma" type="datetime-local"></md-input>
          <span class="md-helper-text">Fecha</span>
        </md-field>
        <md-field>
          <label>Ubicacion</label>
          <md-input :disabled="this.modo == 5" v-model="CalendarioUbicacion"></md-input>
          <span class="md-helper-text">Ubicacion</span>
        </md-field>
        <md-field>
          <label>Estatus</label>
          <md-select :disabled="this.modo == 5" v-model="estatusId">
            <md-option 
            v-for="e in estatus"
                    :key="e.estatusCalendarioId"
                    :value="e.estatusCalendarioId"
                    >{{ e.descripcion }}
             </md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>Creador</label>
          <md-input :disabled="this.modo == 5" v-model="creador"></md-input>
          <span class="md-helper-text">Creador</span>
        </md-field>

          <div class="autocomplete">
            <div style="font-size: smaller; text-align: left">
              <label>Usuario Asignado</label>
            </div> 
            <input :disabled="this.modo == 5" v-model="busqueda" type="text" />
            <ul  class="autocomplete-results" v-if="usuariosFiltrados.length > 0  && this.modo != 5">
              <li class="autocomplete-result" v-for="item in usuariosFiltrados" :key="item.usuariosId" @click="seleccionarUsuario(item)">
                {{ item.usuarioNomCompleto }}
              </li>
            </ul>
          </div>

      </template>
      <template slot="footer">
        <md-button
          class="md-danger"
          style="margin-right: 3px"
          @click="classicModalHide"
          >Cerrar</md-button
        >
        <md-button
          class="md-success"
          style="margin-left: 3px"
          @click="postNow()"
          >Guardar</md-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createEventId } from "./event-utils";
import Swal from "sweetalert2";
import servCal from "@/Servicios/Representacion/calendario.js";
import { Modal } from "@/components";
import store from "@/Store/index.js";
import servRepre from "@/Servicios/Representacion/representacion";
import Fuse from "fuse.js";
import servEstatus from "../../Servicios/Catalogos/estatusCalendario.js";
export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
    // Funcion de bsuqueda de usuarios
    usuariosFiltrados() {
      if (this.busqueda.trim() !== '' && this.fuseSearch) {
        const resultados = this.fuseSearch.search(this.busqueda.trim());
        return resultados.map(resultado => resultado.item);
      } else {
        return [];
      }
    },
  },
  components: {
    FullCalendar,
    Modal,
  },
  data() {
    return {
      //opciones de Calendario para su configuracion
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          center: "dayGridMonth,timeGridWeek,timeGridDay",
          right: "prev,next,today",
        },
        initialView: "dayGridMonth",
        // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        select: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        events: [],
      },
      currentEvents: [],
      classicModal: false,
      CalendarioFechaFirma: "",
      calendarioFechaFin: "",
      creador: "",
      FisicaMoralId: 0,
      UsuariosId: 0,
      //agregar todos los inputs
      CalendarioId: 0,
      CalendarioUbicacion: "",
      CalendarioEstatus: "",
      CalendarioDescripcion: "",
      api: null,
      lista: [],
      tableRepresentacion: [],
      representacionId: [],
      estatus: [],
      fuseSearch: null,
      busqueda: '',
      fechaInicio: null,
      fechaFin: null,
      estatusId: "",
      modo: 1,
    };
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    fechaFinPredeterminada(){
      const today = new Date();
      today.setHours(12, 0, 0, 0);
      const formattedDate = today.toISOString().slice(0, 16);
      return formattedDate;
    },
    //establecemos el formato de las fechas para los filtros de busqueda 
    SetFechas() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.fechaInicio = firstDay.toISOString().split('T')[0];;
      this.fechaFin = lastDay.toISOString().split('T')[0];;
    },
    // funcion para hacer click en la fecha y abrir el modal
    handleDateClick(arg) {
      console.log("date click! ", arg);
      this.classicModal = true;
      this.CalendarioFechaFirma = arg.start;
      this.api = arg;
    },
    // Insertar evento en calendario
    postNow() {
      var title = this.CalendarioDescripcion;
      let calendarApi = this.api.view.calendar;
      if(this.CalendarioDescripcion != "Vacaciones")
      {
        this.calendarioFechaFin = this.fechaFinPredeterminada();
      }
      var data = {
        CalendarioId: this.CalendarioId,
        CalendarioFechaFirma: this.CalendarioFechaFirma,
        CalendarioUbicacion: this.CalendarioUbicacion,
        CalendarioEstatus: this.CalendarioEstatus,
        CalendarioDescripcion: this.CalendarioDescripcion,
        FisicaMoralId: 9769,
        UsuariosId: this.UsuariosId,
        CalendarioFechaFin : this.calendarioFechaFin,
        Creador: this.creador,
        EstatusId: this.estatusId
      };
      servCal.post(data).then((r) => {
        this.Procesamos(r.data);
        if (title) {
          calendarApi.addEvent({
            id: createEventId(),
            title: this.CalendarioDescripcion,
            start: this.CalendarioFechaFirma,
            end: this.CalendarioFechaFirma,
            editable: true,
          });
        }
      });
    },
    // Funcion para recibir la respuesta del back
    Procesamos(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.classicModalHide();
            this.Events();
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    // Cerrar el modal
    classicModalHide() {
      this.classicModal = false;
      this.CalendarioFechaFirma = "";
      this.FisicaMoralId = 0;
      this.UsuariosId = 0;
      //agregar todos los inputs
      this.CalendarioId = 0;
      this.CalendarioUbicacion = "";
      this.CalendarioEstatus = "";
      this.CalendarioDescripcion = "";
      this.busqueda = "";
      this.modo = 1;
      this.estatusId = 0;
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },   
    // Funcion para click en evento y ver su informacion
    handleEventClick(arg) {
      console.log(arg)
      this.usuariosFiltrados.length = 0;
      this.classicModal = true;
      this.CalendarioFechaFirma = arg.event.startStr.substring(0, 16);
      this.busqueda = arg.event.extendedProps.user;
      this.CalendarioUbicacion = arg.event.extendedProps.description;
      this.CalendarioDescripcion = arg.event.title;
      this.estatusId = arg.event.extendedProps.status;
      this.modo = 5;
    },
    // Obtener los usuarios que no son representantes
    getUsers() {
      servCal.getUsuarios().then((r) => {
        this.lista = r.data;
        this.fuseSearch = new Fuse(this.lista, {
          keys : ["usuarioNomCompleto"],
          treshhold : 0.3,
        }) 
        return this.lista;

      });
    },
    // Seleccionar usuario de la busqueda 
    seleccionarUsuario(item) {
      if (item && item.usuarioNomCompleto) {
        this.busqueda = item.usuarioNomCompleto;
        this.usuariosFiltrados.length = 0;
        this.UsuariosId = item.usuariosId;
      }
    },
    // Recuperamos los eventos de la base de datos mas los automatizados
    Events() {
      let data = {
        representaciones: this.representacionId,
        usuarioId: this.usuario.usuariosId,
        fechainicio: this.fechaInicio,
        fechafin: this.fechaFin,
      };  
      servCal
        .getFiltros(data)
        .then((r) => {
          console.log("data", r.data);
          this.calendarOptions.events = r.data;
          this.creador = this.usuario.usuarioNomCompleto;
        })
        .catch((e) => console.log("Error al recuperar los datos", e));

      this.$data.calendarOptions.events.className = "event-red";
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    //Obtenemos las representaciones existentes
    GetRepresentaciones() {
      //cambios
      this.tableRepresentacion = [];
      servRepre
        .all()
        .then((r) => {
          if(this.usuario.areaId == 1){
            let todos = {
            representacionId: 0,
            representacionNombre: "Todas",
          };
          this.tableRepresentacion = r.data; //cambio
          this.tableRepresentacion.unshift(todos);
          }
          else{
            this.tableRepresentacion = r.data;
          }
        })
        .catch((e) => console.log(e));
    },
    getEstatus(){
      servEstatus
        .get()
        .then((p) => {
          this.estatus = p.data;
        })
        .catch((e) => {
          mensaje.Error("usuario-crud-all", e);
        });
    },
  },
  created() {
    this.GetRepresentaciones();
    this.getUsers();
    this.SetFechas();
    this.Events();
    this.getEstatus();
    this.usuariosFiltrados.length = 0;
  },

};
</script>
<style lang="scss" scoped>
#fullCalendar {
  min-height: 300px;
}

.md-card-calendar {
  .md-card-content {
    padding: 0 !important;
  }
}

.text-center {
  text-align: center;
}
.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete input {
  width: 100%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
  border-top: none;
  border-right: none;
  border-left: none;
}

.autocomplete input:focus {
  border-color: #007bff;
  outline: none;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  list-style: none;
}

.autocomplete-result {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.autocomplete-result:hover {
  background-color: #f1f1f1;
}

.autocomplete-result:active {
  background-color: #007bff;
  color: #fff;
}

</style>
