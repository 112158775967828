import { http } from "@/http-commons";

class EstatusCalendario {
    postEstatus(data){
        return http.post('/estatuscalendario',data);
      }
    putEstatus(id, data){
        return http.put(`/estatuscalendario/${id}`,data);
    }  
    delete(id){
        return http.delete(`/estatuscalendario/${id}`);
    }
    get(){
        return http.get('/estatuscalendario');
    }

}
export default new EstatusCalendario();