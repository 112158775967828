import { render, staticRenderFns } from "./Solicitudes.vue?vue&type=template&id=a11b386e"
import script from "./Solicitudes.vue?vue&type=script&lang=js"
export * from "./Solicitudes.vue?vue&type=script&lang=js"
import style0 from "./Solicitudes.vue?vue&type=style&index=0&id=a11b386e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports